var Lib = (function(){

    return {

        money: function(num) {
            return "$" + Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },

        toNumber: function(dollarString) {
            return Number(dollarString.replace(/[\,\$]*/g, ''));
        }

    }

}());